import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import Transfer from "./Pages/Transfer";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="register/:vin" element={<Register />} />
      <Route path="transfer/:vin" element={<Transfer />} />
    </Routes>
  );
};

export default App;
