import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

export default function Navigation() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: "#fff" }}>
        <Toolbar variant="dense">
          <Box
            onClick={() => (window.location.href = "/")}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <img src="/images/logo.png" alt="logo" style={{ height: "20px" }} />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
