import axios from "axios";
import { backendUrl } from "../utils/constants";
import { Vehicle } from "../utils/types";

export interface IIssueOwnershipVCBody extends Vehicle {
  name: string;
  address: string;
  relationshipDID: string;
}

export interface IIssueHoldershipVCBody extends IIssueOwnershipVCBody {
  licensePlate: string;
}

export interface ITransferOwnershipBody extends Vehicle {
  nameOfCurrentOwner: string;
  nameOfNextOwner: string;
  address: string;
  relationshipDID: string;
}

export interface ITransferHoldershipBody extends ITransferOwnershipBody {
  licensePlate: string;
}

export const issueOwnershipVC = async (
  body: IIssueOwnershipVCBody
): Promise<any> => {
  const response = await axios.post(
    `${backendUrl}/v1/issue-ownership-vc`,
    body
  );
  return response.data;
};

export const issueHoldershipVC = async (
  body: IIssueHoldershipVCBody
): Promise<any> => {
  const response = await axios.post(
    `${backendUrl}/v1/issue-holdership-vc`,
    body
  );
  return response.data;
};

export const transferOwnershipVC = async (
  body: ITransferOwnershipBody
): Promise<any> => {
  const response = await axios.post(
    `${backendUrl}/v1/transfer-ownership-vc`,
    body
  );
  return response.data;
};

export const transferHoldershipVC = async (
  body: ITransferHoldershipBody
): Promise<any> => {
  const response = await axios.post(
    `${backendUrl}/v1/transfer-holdership-vc`,
    body
  );
  return response.data;
};
