import { CheckCircle } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import useNavigation from "../Hooks/useNavigation";

import { useTransferVehicle } from "../Hooks/useRegister";
import VehicleDetails from "./VehicleDetails";

interface IRegisterVehicle {
  vin: string;
  relationshipDid: string;
}

const TransferVehicle: FC<IRegisterVehicle> = ({ vin, relationshipDid }) => {
  const {
    isVehicleDetailsLoading,
    vehicleData,
    vehicleDetailsError,
    ownershipVCLoading,
    ownershipVCData,
    ownershipVCErr,
    holdershipVCLoading,
    holdershipVCData,
    holdershipVCErr,
    transferVCType,
    handleDialogOpen,
    handleDialogClose,
  } = useTransferVehicle(vin, relationshipDid);

  const { navigateHome } = useNavigation();
  const [sellerName, setSellerName] = useState("");

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      pt={10}
      pb={10}
    >
      <>
        <img
          src="/images/logo_2.png"
          alt="logo"
          style={{ maxWidth: "350px" }}
        />

        <>
          <VehicleDetails
            vehicleData={vehicleData}
            isVehicleDetailsLoading={isVehicleDetailsLoading}
            vehicleDetailsError={vehicleDetailsError as string}
          />
          <Box display="flex" flexDirection="row">
            <h4>Name of Seller</h4>
            <Button onClick={() => setSellerName("Alice Doe")}>Alice</Button>
            <Button onClick={() => setSellerName("Bob Doe")}>Bob</Button>
          </Box>
          <TextField
            label="Seller name"
            value={sellerName}
            onChange={(e) => setSellerName(e.target.value)}
          />
          {vehicleData && (
            <>
              <Box
                mt={3}
                mb={3}
                display="flex"
                columnGap={3}
                rowGap={3}
                flexWrap="wrap"
                justifyContent={"center"}
              >
                <Button
                  variant="contained"
                  onClick={() => handleDialogOpen("ownership")}
                  disabled={
                    ownershipVCLoading || ownershipVCErr || ownershipVCData
                  }
                  color={ownershipVCData ? "success" : "primary"}
                  startIcon={ownershipVCData ? <CheckCircle /> : null}
                >
                  {ownershipVCData
                    ? "Ownership VC transferred"
                    : "Transfer ownership VC"}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleDialogOpen("holdership")}
                  disabled={
                    !ownershipVCData ||
                    holdershipVCLoading ||
                    holdershipVCErr ||
                    holdershipVCData
                  }
                  color={holdershipVCData ? "success" : "primary"}
                  startIcon={holdershipVCData ? <CheckCircle /> : null}
                >
                  {holdershipVCData
                    ? "Holdership VC transferred"
                    : "Transfer holdership VC"}
                </Button>
              </Box>
              {ownershipVCLoading || holdershipVCLoading ? (
                <Box
                  display="flex"
                  flexDirection={"column"}
                  mt={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <b>Please accept the credential offer on your device</b>
                  <CircularProgress sx={{ mt: 2 }} />
                </Box>
              ) : ownershipVCErr || holdershipVCErr ? (
                <div>Error issuing vc</div>
              ) : ownershipVCData || holdershipVCData ? (
                <>
                  {ownershipVCData && (
                    <Box
                      color="green"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      columnGap={1}
                      mb={1}
                    >
                      <CheckCircle fontSize="medium" />{" "}
                      <b>Ownership VC transferred successfully</b>
                    </Box>
                  )}{" "}
                  {holdershipVCData && (
                    <Box
                      color="green"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      columnGap={1}
                    >
                      <CheckCircle fontSize="medium" />{" "}
                      <b>Holdership VC transferred successfully</b>
                    </Box>
                  )}
                </>
              ) : null}
            </>
          )}
        </>
        <Button onClick={navigateHome} sx={{ mt: 3 }}>
          Back to Home
        </Button>
      </>
      <TransferVCDialog
        sellerName={sellerName}
        vc={transferVCType}
        onClose={handleDialogClose}
      />
    </Box>
  );
};

interface ITransferOwnershipDialogProps {
  vc: "ownership" | "holdership" | null;
  sellerName: string;
  onClose: (
    nameOfCurrentOwner: string,
    nameOfNextOwner: string,
    address: string,
    licensePlate: string,
    submit?: boolean
  ) => void;
}

const TransferVCDialog: FC<ITransferOwnershipDialogProps> = ({
  sellerName,
  onClose,
  vc,
}) => {
  const [nameOfNextOwner, setName] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [address, setAddress] = useState("");
  const [bobDataLoading, setBobDataLoading] = useState(false);
  const [aliceDataLoading, setAliceDataLoading] = useState(false);

  const handleClose = () => {
    onClose(sellerName, nameOfNextOwner, address, licensePlate);
  };

  const handleSubmit = () => {
    onClose(sellerName, nameOfNextOwner, address, licensePlate, true);
  };

  const autoFill = (person: "bob" | "alice") => {
    switch (person) {
      case "bob":
        setBobDataLoading(true);
        setTimeout(() => {
          setBobDataLoading(false);
          setName("Bob Doe");
          setAddress("Bobby Street 8 Hamburg Germany 54321");
        }, 2000);
        break;
      case "alice":
        setAliceDataLoading(true);
        setTimeout(() => {
          setAliceDataLoading(false);
          setName("Alice Doe");
          setAddress("Wonderland Street 12 Paris France 23456");
        }, 2000);
        break;
    }
  };

  return (
    <Dialog open={!!vc} onClose={handleClose} maxWidth="xs" fullWidth>
      <Backdrop
        sx={{ color: "#fff", zIndex: 1 }}
        open={bobDataLoading || aliceDataLoading}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="inherit" />
          <Typography>Loading data from VC</Typography>
        </Box>
      </Backdrop>
      <DialogTitle>{`Transfer ${vc} VC`}</DialogTitle>

      <DialogContent>
        {vc === "ownership" && (
          <DialogContentText>
            Please enter the name and address
          </DialogContentText>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Buyer Name"
          type="text"
          fullWidth
          value={nameOfNextOwner}
          disabled={vc === "holdership"}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="address"
          label="Buyer Address"
          type="text"
          fullWidth
          value={address}
          disabled={vc === "holdership"}
          onChange={(e) => setAddress(e.target.value)}
        />

        {vc === "holdership" && (
          <>
            <DialogContentText>
              Please enter your license plate.
            </DialogContentText>
            <TextField
              margin="dense"
              id="licensePlate"
              label="License Plate"
              type="text"
              fullWidth
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value)}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        {vc === "ownership" && (
          <>
            {!sellerName.startsWith("Bob") && (
              <Button onClick={() => autoFill("bob")} color="secondary">
                BOB DATA
              </Button>
            )}
            {!sellerName.startsWith("Alice") && (
              <Button onClick={() => autoFill("alice")} color="secondary">
                ALICE DATA
              </Button>
            )}
          </>
        )}
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!nameOfNextOwner || (vc === "holdership" && !licensePlate)}
        >
          Transfer VC
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferVehicle;
