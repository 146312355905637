import axios from "axios";
import { backendUrl } from "../utils/constants";

export const getInviteUrl = async (): Promise<any> => {
  const response = await axios.get(`${backendUrl}/v1/invite-url`);
  const inviteUrl = response.data;
  if (!inviteUrl) {
    throw new Error("Invite URL not found");
  }
  return response.data;
};

export const createConnection = async (): Promise<any> => {
  const response = await axios.post(`${backendUrl}/v1/create-connection`, {});
  const relationshipDid = response.data;
  console.log(relationshipDid);
  return response.data;
};
