import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation";
import { CreatedVehicle } from "../Components/CreatedVehicle";
import { SelectedVehicle } from "../Components/SelectedVehicle";
import { useState } from "react";

const Home: React.FC = () => {
  const [selectedVehicleVin, setselectedVehicleVin] = useState("");

  return (
    <>
      <Navigation />
      <CreatedVehicle setSelectedVehicleVin={setselectedVehicleVin} />
      <SelectedVehicle selectedVehicleVin={selectedVehicleVin} />
      <Footer />
    </>
  );
};

export default Home;
