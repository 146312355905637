import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { createConnection, getInviteUrl } from "../Apis/connection";
import {
  IIssueHoldershipVCBody,
  IIssueOwnershipVCBody,
  issueHoldershipVC,
  issueOwnershipVC,
  ITransferHoldershipBody,
  ITransferOwnershipBody,
  transferHoldershipVC,
  transferOwnershipVC,
} from "../Apis/vc";
import { getVehicle } from "../Apis/vehicles";
import { Vehicle } from "../utils/types";

export const useRegister = (vin: string) => {
  const navigate = useNavigate();

  const [triggerInvite, setTriggerInvite] = useState(false);

  const { data: connection } = useQuery<{
    relationshipDid: string;
  }>(["createConnection", vin], createConnection, {
    staleTime: Infinity,
    retry: false,
  });

  const { isLoading: inviteUrlLoading, data: inviteUrl } = useQuery<string>(
    ["inviteUrl", vin],
    getInviteUrl,
    {
      enabled: triggerInvite,
      retryDelay: 2000,
      retry: 20,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    setTimeout(() => {
      setTriggerInvite(true);
    }, 5000);
  }, []);

  useEffect(() => {
    const saveRelationshipDID = (relationshipDID: string) => {
      localStorage.setItem("relationshipDid", relationshipDID);
      navigate(0);
    };
    if (connection && connection.relationshipDid) {
      saveRelationshipDID(connection.relationshipDid);
    }
  }, [connection, navigate]);

  return {
    inviteUrlLoading,
    inviteUrl,
  };
};

export const useRegisterVehicle = (vin: string, relationshipDid: string) => {
  const {
    isLoading: isVehicleDetailsLoading,
    data: vehicleData,
    error: vehicleDetailsError,
  } = useQuery<Vehicle>(["vehicle", vin], () => getVehicle(vin));

  const [ownershipVcBody, setOwnershipVcBody] =
    useState<IIssueOwnershipVCBody | null>(null);

  const [holdershipVcBody, setHoldershipVcBody] =
    useState<IIssueHoldershipVCBody | null>(null);

  const {
    isLoading: ownershipVCLoading,
    data: ownershipVCData,
    error: ownershipVCErr,
  } = useQuery<any>(
    ["ownershipVC", vin],
    () => issueOwnershipVC(ownershipVcBody!),
    {
      enabled: !!ownershipVcBody,
      retry: false,
    }
  );

  const {
    isLoading: holdershipVCLoading,
    data: holdershipVCData,
    error: holdershipVCErr,
  } = useQuery<any>(
    ["holdershipVC", vin],
    () => issueHoldershipVC(holdershipVcBody!),
    {
      enabled: !!holdershipVcBody,
      retry: false,
    }
  );

  const [issueVC, setIssueVC] = useState<"ownership" | "holdership" | null>(
    null
  );

  const handleIssueOwnership = (name: string, address: string) => {
    setOwnershipVcBody({
      ...vehicleData!,
      name,
      address,
      relationshipDID: relationshipDid,
    });
  };

  const handleIssueHoldership = (
    name: string,
    address: string,
    licensePlate: string
  ) => {
    setHoldershipVcBody({
      ...vehicleData!,
      name,
      address,
      licensePlate,
      relationshipDID: relationshipDid,
    });
  };

  const handleDialogOpen = (vc: "ownership" | "holdership") => {
    setIssueVC(vc);
  };

  const handleDialogClose = (
    name: string,
    address: string,
    licensePlate: string,
    submit?: boolean
  ) => {
    if (licensePlate && submit)
      handleIssueHoldership(name, address, licensePlate);
    if (!licensePlate && submit) handleIssueOwnership(name, address);
    setIssueVC(null);
  };

  return {
    isVehicleDetailsLoading,
    vehicleData,
    vehicleDetailsError,
    ownershipVCLoading,
    ownershipVCData,
    ownershipVCErr,
    holdershipVCLoading,
    holdershipVCData,
    holdershipVCErr,
    issueVC,
    handleDialogOpen,
    handleDialogClose,
  };
};

export const useTransferVehicle = (vin: string, relationshipDid: string) => {
  const {
    isLoading: isVehicleDetailsLoading,
    data: vehicleData,
    error: vehicleDetailsError,
  } = useQuery<Vehicle>(["vehicle", vin], () => getVehicle(vin));

  const [ownershipVcBody, setOwnershipVcBody] =
    useState<ITransferOwnershipBody | null>(null);

  const [holdershipVcBody, setHoldershipVcBody] =
    useState<ITransferHoldershipBody | null>(null);

  const {
    isLoading: ownershipVCLoading,
    data: ownershipVCData,
    error: ownershipVCErr,
  } = useQuery<any>(
    ["transferOwnershipVC", vin],
    () => transferOwnershipVC(ownershipVcBody!),
    {
      enabled: !!ownershipVcBody,
      retry: false,
    }
  );

  const {
    isLoading: holdershipVCLoading,
    data: holdershipVCData,
    error: holdershipVCErr,
  } = useQuery<any>(
    ["transferHoldershipVC", vin],
    () => transferHoldershipVC(holdershipVcBody!),
    {
      enabled: !!holdershipVcBody,
      retry: false,
    }
  );

  const [transferVCType, setTransferVCType] = useState<
    "ownership" | "holdership" | null
  >(null);

  const handleIssueOwnership = (
    nameOfCurrentOwner: string,
    nameOfNextOwner: string,
    address: string
  ) => {
    setOwnershipVcBody({
      ...vehicleData!,
      nameOfCurrentOwner,
      nameOfNextOwner,
      address,
      relationshipDID: relationshipDid,
    });
  };

  const handleIssueHoldership = (
    nameOfCurrentOwner: string,
    nameOfNextOwner: string,
    address: string,
    licensePlate: string
  ) => {
    setHoldershipVcBody({
      ...vehicleData!,
      nameOfCurrentOwner,
      nameOfNextOwner,
      address,
      licensePlate,
      relationshipDID: relationshipDid,
    });
  };

  const handleDialogOpen = (vc: "ownership" | "holdership") => {
    setTransferVCType(vc);
  };

  const handleDialogClose = (
    nameOfCurrentOwner: string,
    nameOfNextOwner: string,
    address: string,
    licensePlate: string,
    submit?: boolean
  ) => {
    if (licensePlate && submit)
      handleIssueHoldership(
        nameOfCurrentOwner,
        nameOfNextOwner,
        address,
        licensePlate
      );
    if (!licensePlate && submit)
      handleIssueOwnership(nameOfCurrentOwner, nameOfNextOwner, address);
    setTransferVCType(null);
  };

  return {
    isVehicleDetailsLoading,
    vehicleData,
    vehicleDetailsError,
    ownershipVCLoading,
    ownershipVCData,
    ownershipVCErr,
    holdershipVCLoading,
    holdershipVCData,
    holdershipVCErr,
    transferVCType,
    handleDialogOpen,
    handleDialogClose,
  };
};
