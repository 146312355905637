import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";
import { Vehicle } from "../utils/types";

interface IVehicleDetailsProps {
  vehicleData?: Vehicle;
  isVehicleDetailsLoading?: boolean;
  vehicleDetailsError?: string;
}

const VehicleDetails: FC<IVehicleDetailsProps> = ({
  vehicleData,
  isVehicleDetailsLoading,
  vehicleDetailsError,
}) => {
  return (
    <Box
      sx={{
        maxWidth: "100vw",
        marginLeft: "20px",
        marginRight: "20px",
        overflowWrap: "anywhere",
      }}
    >
      <Box mt={4}>
        <b>Vehicle Details</b>
      </Box>
      {isVehicleDetailsLoading && <CircularProgress />}
      {vehicleDetailsError && <Box mt={3}>Error</Box>}
      {vehicleData && (
        <Box mt={3}>
          <Box mb={1}>
            <b>• Make</b>
          </Box>
          <div>{vehicleData.make}</div>
          <Box mb={1} mt={2}>
            <b>• Model</b>
          </Box>
          <div>{vehicleData.model}</div>
          <Box mb={1} mt={2}>
            <b>• Year</b>
          </Box>
          <div>{vehicleData.year}</div>
          <Box mb={1} mt={2}>
            <b>• Color</b>
          </Box>
          <div>{vehicleData.color}</div>
          <Box mb={1} mt={2}>
            <b>• VIN</b>
          </Box>
          <div>{vehicleData.vin}</div>
          <Box mb={1} mt={2}>
            <b>• VehicleDID</b>
          </Box>
          <div>{vehicleData.vehicleDID}</div>
        </Box>
      )}
    </Box>
  );
};

export default VehicleDetails;
