import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navigation from "../Components/Navigation";

import InviteWithQR from "../Components/InviteWithQR";
import TransferVehicle from "../Components/TransferVehicle";

const Transfer: FC = () => {
  const { vin } = useParams();
  const relationshipDid = localStorage.getItem("relationshipDid");

  useEffect(() => {
    if (!relationshipDid) return;
    localStorage.removeItem("relationshipDid"); //TODO only comment for developement
  }, [relationshipDid]);

  if (!vin) {
    return <p>No vin provided!</p>;
  }

  return (
    <>
      <Navigation />
      {relationshipDid ? (
        <TransferVehicle vin={vin} relationshipDid={relationshipDid} />
      ) : (
        <InviteWithQR vin={vin} mode="transfer" />
      )}
    </>
  );
};

export default Transfer;
