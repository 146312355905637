import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";
import { QRCode } from "react-qrcode-logo";

import { useRegister } from "../Hooks/useRegister";

interface IRegisterWithQRProps {
  vin: string;
  mode: "register" | "transfer";
}

const RegisterWithQR: FC<IRegisterWithQRProps> = ({ vin, mode }) => {
  const { inviteUrlLoading, inviteUrl } = useRegister(vin);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <h1>
        {mode === "register" ? "Scan to Register" : "Buyer Scan To Transfer"}
      </h1>
      {inviteUrlLoading ? (
        <CircularProgress />
      ) : inviteUrl ? (
        <QRCode
          size={350}
          qrStyle="dots"
          value={inviteUrl}
          logoImage="/images/small_logo_qr.png"
          removeQrCodeBehindLogo
          eyeRadius={6}
        />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default RegisterWithQR;
