import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import * as React from "react";
import { useQuery } from "react-query";
import { getAllVehicles } from "../Apis/vehicles";

import { Vehicle } from "../utils/types";

const PREFIX = "CreatedVehicle";

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableRow: `${PREFIX}-tableRow`,
};

const Main = styled(Box)({
  backgroundColor: "#37afb9",
  minHeight: "300px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  [`& .${classes.title}`]: {
    color: "white",
    fontWeight: 500,
  },
  [`& .${classes.subtitle}`]: {
    color: "white",
    fontWeight: 400,
  },
  [`& .${classes.tableContainer}`]: {
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "10px",
    border: "2px solid darkgray",
    maxWidth: "800px",
    width: "100%",
    maxHeight: "350px",
    overflow: "auto",
  },
  [`& .${classes.tableRow}`]: {
    "&:last-child td, &:last-child th": { border: 0 },
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
  },
});
interface CreatedVehicleProps {
  setSelectedVehicleVin: React.Dispatch<React.SetStateAction<string>>;
}
export const CreatedVehicle: React.FC<CreatedVehicleProps> = (props) => {
  const { isLoading, data: vehicles } = useQuery<Vehicle[]>(
    "allVehicles",
    getAllVehicles
  );
  return (
    <Main>
      <Typography variant="h5" className={classes.title}>
        Created Vehicles
      </Typography>

      <Typography variant="body1" className={classes.subtitle}>
        Click on a vehicle to see more information
      </Typography>

      {isLoading ? (
        <Box mt={3}>
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={3} className={classes.tableContainer}>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Make</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Model</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Year</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Color</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vehicles &&
                  vehicles.map((vehicle, index) => (
                    <TableRow
                      key={index}
                      onClick={() => props.setSelectedVehicleVin(vehicle.vin)}
                      className={classes.tableRow}
                    >
                      <TableCell component="th" scope="row">
                        {vehicle.make}
                      </TableCell>
                      <TableCell align="right">{vehicle.model}</TableCell>
                      <TableCell align="right">{vehicle.year}</TableCell>
                      <TableCell align="right">{vehicle.color}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Main>
  );
};
