import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getVehicle } from "../Apis/vehicles";

import { Vehicle } from "../utils/types";

interface SelectedVehicleProps {
  selectedVehicleVin: string;
}

export const emptyVehicleData: Vehicle = {
  _id: "",
  vin: "",
  make: "",
  model: "",
  year: 0,
  color: "",
  vehicleDID: "",
};

export const SelectedVehicle: React.FC<SelectedVehicleProps> = (props) => {
  const navigate = useNavigate();
  const { selectedVehicleVin } = props;
  const { data: vehicleDetails } = useQuery<Vehicle>(
    ["vehicle", selectedVehicleVin],
    () => getVehicle(selectedVehicleVin),
    {
      enabled: !!selectedVehicleVin,
      initialData: emptyVehicleData,
    }
  );

  const handleRegister = () => {
    navigate(`/register/${selectedVehicleVin}`);
  };

  const handleTransfer = () => {
    navigate(`/transfer/${selectedVehicleVin}`);
  };

  return (
    <Container disableGutters sx={{ mb: 3 }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        rowSpacing={2}
        sx={{ m: 0, p: 2 }}
      >
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: "500" }}>
            Selected Vehicle
          </Typography>
        </Grid>
        <Grid item xs={12} width="300px">
          <TextField
            fullWidth
            label="Make"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={vehicleDetails?.make}
            disabled
          />
        </Grid>
        <Grid item xs={12} width="300px">
          <TextField
            fullWidth
            label="Model"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={vehicleDetails?.model}
            disabled
          />
        </Grid>
        <Grid item xs={12} width="300px">
          <TextField
            fullWidth
            label="Year"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={vehicleDetails?.year}
            disabled
          />
        </Grid>
        <Grid item xs={12} width="300px">
          <TextField
            fullWidth
            label="Color"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={vehicleDetails?.color}
            disabled
          />
        </Grid>
        <Grid item xs={12} width="300px">
          <TextField
            fullWidth
            label="VIN"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={vehicleDetails?.vin}
            disabled
          />
        </Grid>
        <Grid item xs={12} width="300px">
          <TextField
            fullWidth
            label="DID"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={vehicleDetails?.vehicleDID}
            disabled
          />
        </Grid>
        <Grid item xs={12} width="300px">
          <Box display="flex" justifyContent="space-between">
            <Button
              disabled={
                !selectedVehicleVin ||
                vehicleDetails?.ownershipVcIssued ||
                vehicleDetails?.holdershipVcIssued
              }
              variant="contained"
              onClick={handleRegister}
            >
              Register
            </Button>
            <Button
              disabled={
                !selectedVehicleVin ||
                (!vehicleDetails?.ownershipVcIssued &&
                  !vehicleDetails?.holdershipVcIssued)
              }
              variant="contained"
              onClick={handleTransfer}
            >
              Transfer
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
